<template>
    <div class="details-wrap">
        <global-page-back
            :detailPageTitle="pageTitle"
            @handlerGlobalType="handleBack"
        >
            <template #globalPageBackTabs>
                <div class="tabs-list">
                    <div :class="['tabs-item',{ current: leftActiveName === 1 }]" @click="leftTabClick(1)">详情</div>
                    <div :class="['tabs-item',{ current: leftActiveName === 2 }]" @click="leftTabClick(2)">流程</div>
                    <div 
                        v-if="showSubExcuseTab"
                        :class="['tabs-item',{ current: leftActiveName === 3 }]" 
                        @click="leftTabClick(3)"
                    >子假条</div>
                </div>
            </template>
            <template #globalPageBackRight>
                <el-button type="enquiry" @click="exportPDF" style="margin-right: 10px;">导出PDF</el-button>
            </template>
        </global-page-back>
        <div class="apply-detail-wrap content-bg" v-if="leftActiveName != 3">
            <div class="apply-detail-content">
                <!--详情-->
                <template v-if="leftActiveName === 1">
                    <div v-if="isShowExcuseStatus && showOldStatus">
                        <img v-if="schoolProcessInfo.excuseState=='1'" class="awaySchoolStatus" :src="awayFromSchoolImg" alt="待出校">
                        <img v-else-if="schoolProcessInfo.excuseState=='2'" class="awaySchoolStatus" :src="tobeAdmittedToSchoolImg" alt="待入校">
                        <img v-else-if="schoolProcessInfo.excuseState=='3'" class="awaySchoolStatus" :src="endedImg" alt="已结束">
                        <img v-else-if="schoolProcessInfo.excuseState=='4'" class="awaySchoolStatus" :src="hasCancelLeaveImg" alt="已销假">
                    </div>
                    <div v-if="isShowExcuseStatus && showTempStatus">
                        <img v-if="schoolProcessInfo.excuseTempState=='1'" class="awaySchoolStatus" :src="cancelingImg" alt="撤销中">
                        <img v-else-if="schoolProcessInfo.excuseTempState=='2'" class="awaySchoolStatus" :src="editingImg" alt="修改中">
                    </div>
                    <apply-form-data
                        :form-opt-data="formOptData"
                        :serial-number="schoolProcessInfo.serialNumber"
                        @leaveDurationDetailsShow="handleLeaveDurationDetailsShow"
                    >
                        <div
                            v-if="formInfo.isApprove || formInfo.isEdit || formInfo.isEdit || formInfo.isManualCancelLeave"
                            class="split-line"
                        />
                        <el-form-item v-if="showInfoNeedApply" label="入校提交申请" label-width="110px" class="operate-radio">
                            <template>
                                
                                <el-radio-group class="dis-radio-class" size="medium" disabled v-model="infoAdmissionNeedApply">
                                    <el-radio size="medium" :label="1">是</el-radio>
                                </el-radio-group>
                            </template>
                        </el-form-item>
                        <el-form-item v-if="formInfo.isApprove && showNeedApply && formInfo.schoolProcessApplyInfo.isRevoke != 1" label="入校提交申请" label-width="110px" class="operate-radio">
                            <template>
                                
                                 <el-radio-group size="medium" v-model="admissionNeedApply" @change="handleChangeNeedApply($event)">
                                    <el-radio
                                        size="medium"
                                        v-for="(item ,index) in admissionNeedApplyOptions"
                                        :key="index"
                                        :label="item.lable"
                                    >{{ item.value }}
                                    </el-radio>
                                </el-radio-group>
                            </template>
                        </el-form-item>
                        <el-form-item 
                            :label-width="opinionLabelWidth" 
                            class="operate-btn"
                        >
                            <el-input
                                v-model="approveOpinion"
                                type="textarea"
                                rows="5"
                                placeholder="审批意见 (可选) :"
                                maxlength="200"
                                show-word-limit
                                class="advice-input"
                            />
                            <template v-if="formInfo.isApprove">
                                <el-button type="success" @click="handleClick(2)">同意</el-button>
                                <el-button type="danger" @click="handleClick(3)">拒绝</el-button>
                            </template>
                            <el-button
                                v-if="formInfo.isRevoke"
                                type="warning"
                                @click="handleRevoke()"
                            >撤销</el-button>
                            <el-button
                                v-if="formInfo.isEdit"
                                type="primary"
                                @click="handleEdit()"
                            >编辑</el-button>
                            <el-button
                                class="cancel-leave-btn"
                                v-if="formInfo.isManualCancelLeave"
                                v-hasPermi="['oaForm:returnWorkTeacherPC']"
                                type="primary"
                                @click="handleCancelLeave()"
                            >销假</el-button>
                        </el-form-item>
                    </apply-form-data>
                </template>
                <!--流程-->
                <approval-step
                    v-else-if="leftActiveName === 2"
                    :processData="formInfo.processList"
                    :old-data="formInfo.oldProcessListArray"
                />
            </div>
        </div>
        <!-- 子假条 -->
        <excuse-list
            v-if="leftActiveName === 3"
            :form-info="formInfo"
            :subExcuseData="subExcuseData"
            @handleGoSubExcuseDetail="handleGoSubExcuseDetail"
            @getSubExcuseList="getSubExcuseList"
            @handleAfterRevokeAll="handleAfterRevokeAll"
        />
        <!-- 请假时长明细弹窗 -->
        <dialog-wrapper :dialogObj="leaveDurationDetailsDialogObj" @handleClose="handleLeaveDurationDetailsClose">
            <leave-duration-details
                :key="+new Date()"
                :params="leaveDurationDetailsDialogObj.data"
                @close="handleLeaveDurationDetailsClose"
            ></leave-duration-details>
        </dialog-wrapper>
        <!-- 销假弹窗 -->
        <cancel-leave-dialog v-if="showCancelLeave" @handleCancelLeaveClose="handleCancelLeaveClose" @handleCancel="handleCancel" :formData="formData"></cancel-leave-dialog>
        <!-- 审批同意弹窗 -->
        <dialog-wrapper 
            :dialogObj="approvalDialogObj" 
            :customClass="approvalDialogObj.customClass"
            @handleClose="handleApprovalCancel"
        >
            <ApprovalApproved
                :key="approvalDialogObj.key"
                :approveOpinion="approvalDialogObj.approveOpinion"
                :data="approvalDialogObj.data"
                :admissionNeedApplyData="approvalDialogObj.admissionNeedApplyData"
                @handleClose="handleApprovalCancel"
                @handleConfirm="handleApprovalConfirm"
            />
        </dialog-wrapper>
        
        <!-- 导出PDF -->
        <dialog-wrapper
            :dialogObj="dialogObj"
            @handleClose="handleDialogClose"
        >
            <div :style="{
                width: '100%',
                height: '500px',
                marginBottom: '10px',
                overflowY: 'auto'
            }">
                <pdf
                    v-for="item in pageTotal"
                    :src="pdfUrl"
                    :key="item"
                    :page="item"
                    :scale="0.8"
                ></pdf>
            </div>

            <div
                :style="{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }"
            >
                <el-button type="primary" @click="download">导出PDF</el-button>
            </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import {CampusOAModel} from "@/models/CampusOA";
import approvalStep from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/applyDetail/applyProcess.vue";
import {mapMutations, mapState} from "vuex";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import ApplyFormData from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/applyDetail/applyFormData.vue";
import LeaveDurationDetails from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/formDetail/leaveDurationDetails.vue";
import CancelLeaveDialog from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/applyDetail/cancelLeaveDialog.vue";
import ExcuseList from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/applyDetail/excuseList.vue";
// 审批同意弹窗
import ApprovalApproved from "@/components/scrollWrapper/CoworkingCampusOAOAApproval/applyDetail/approvalApproved.vue";
import { DialogWrapper } from 'common-local';
import {CoworkingCampusOAVacationManagementModel} from "@/models/CoworkingCampusOAVacationManagement.js";

// 导出pdf
import Pdf from "vue-pdf-signature";
import CMapReaderFactory from "vue-pdf-signature/src/CMapReaderFactory.js";

export default {
    name: "CoworkingCampusOAOAApprovalApplyDetail",
    components: {
        ApplyFormData,
        GlobalPageBack,
        approvalStep,
        DialogWrapper,
        LeaveDurationDetails,
        CancelLeaveDialog,
        ExcuseList,
        ApprovalApproved,
        Pdf,
    },
    data() {
        return {
            // 学生类型
            studentTypeList:[
                {
                    value:0,
                    label:'住宿'
                },{
                    value:1,
                    label:'走读'
                },{
                    value:2,
                    label:'半读'
                }
            ],
            // 特殊处理
            specialHandling: [
                {
                    schoolId: '311',
                    rules: {
                        value1: {
                            show: true,
                            labelName: '请假日期',

                        },
                        value2: {
                            show: false,
                            labelName: '结束时间',
                        }
                    }
                },
                {
                    schoolId: '222',
                    rules: {
                        value1: {
                            show: true,
                            labelName: '请假日期',

                        },
                        value2: {
                            show: false,
                            labelName: '结束时间',
                        }
                    }
                }
            ],
            leftActiveName: 1, // 1详情  2流程 3子假条
            formInfo: {}, // 表单详情数据
            formOptData: [], // 表单配置数据
            admissionNeedApply: '2',  // 入校需要提交入校申请 默认是【否】
            admissionNeedApplyOptions: [
                {
                    lable: '1',
                    value: '是'
                },
                {
                    lable: '2',
                    value: '否'
                }
            ], // 入校需要提交入校申请 选项
            approveOpinion: '', // 审批意见
            applyContent: {},
            isShowExcuseStatus: false, // 是否显示待入校等图标
            schoolProcessInfo:{},
            leaveDurationDetailsDialogObj: {
                title: '查看明细',
                dialogVisible: false,
                width: 'auto',
                data: {}
            },
            showCancelLeave: false, //是否显示销假弹框
            isAskForLeaveForm: false, //是不是请假表单
            formData:{},
            subExcuseData: {},//特殊请假-子假条信息
            subJumpToDetailLoading: false,//特殊请假子假条跳转详情中
            infoAdmissionNeedApply: 1,  // 只有为[ 1：是 ]时，才展示入校申请
            isMasterTeacher: false,  // 是否是班主任
            showNeedApply: false,  // 是否展示入校申请
            showBalance: false,
            overLeaveTime: 0,
            formTitle: '',
            useVacationList: [],   // 展示的请假类型
            isOverLeaveUnit: false,  // 时长是否超过余额
            approvalDialogObj: {
                title: '审批同意意见',
                dialogVisible: false,
                width: '690px',
                approveOpinion: "",
                data: "",
                admissionNeedApplyData: "",
                customClass: 'approval-dialog',
                key: 0,
            },
            // 导出pdf相关参数
            dialogObj: {
                title: "表单导出预览",
                dialogVisible: false,
                width: "800px",
            },
            pageTotal: 0,
            pdfUrl:"",
            pdfDownLoadUrl:"",
            pdfFileName:"",
            mockUrl:"https://minio.xinkaoyun.com:9000/cloud-campus/school/2023-03/common/233c5d350c9e4d99aab976bf246e0dbd_3%E6%9C%882%E6%97%A5%E6%97%A5%E5%BF%97%E6%A3%80%E6%9F%A5%E9%80%9A%E6%8A%A5.pdf",
        }
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            OAQueryData: state => state.OAQueryData
        }),
        pageTitle() {
            if (this.formInfo && this.formInfo.schoolProcessApplyInfo && this.formInfo.schoolProcessApplyInfo.dataTitle) {
                return this.formInfo?.schoolProcessApplyInfo?.dataTitle
            } else {
                return ''
            }

        },
        endedImg() {
            return require('@/assets/images/ended.png')
        },
        tobeAdmittedToSchoolImg() {
            return require('@/assets/images/tobeAdmittedToSchool.png')
        },
        awayFromSchoolImg() {
            return require('@/assets/images/awayFromSchool.png')
        },
        hasCancelLeaveImg() {
            return require('@/assets/images/cancelLeave.png')
        },
        cancelingImg() {
            return require('@/assets/images/canceling.png')
        },
        editingImg() {
            return require('@/assets/images/editing.png')
        },
        showCancelLeaveBtn() {
            return true && this.isAskForLeaveForm;
        },
        showOldStatus() {//不显示假条临时状态   显示原有的假条状态
            return !['1', '2'].includes(this.schoolProcessInfo.excuseTempState);
        },
        showTempStatus() {
            return !this.showOldStatus;
        },
        showSubExcuseTab() {//展示子假条tab
            let isShow = false;
            // 获取表单 formType ，3为特殊请假
            let formType = this.formInfo.schoolProcessApplyInfo ? this.formInfo.schoolProcessApplyInfo.formType : "";
            // 获取子假条列表长度
            let subLength = this.subExcuseData.list ? this.subExcuseData.list.length : 0;
            // 如果formType为3且子假条列表长度大于0，则展示子假条tab
            if(formType == "3" && subLength > 0){
                isShow = true;
            }
            return isShow;
        },
        showInfoNeedApply() {
            // 表单详情 只有选择了 1 是 时，才展示入校申请组件
            let isShowInfoNeedApply = false;
            let specials = this.specialHandling.map(item => {
                return item.schoolId;
            });
            if(specials.includes(this.schoolId)) {
                let currentAdmissionNeedApply = this.applyContent.admissionNeedApply?.value;
                if (currentAdmissionNeedApply && currentAdmissionNeedApply == '1') {
                    // 如果 值为 1， 则展示，其他的不展示该字段
                    isShowInfoNeedApply = true;
                }
            }
            return isShowInfoNeedApply;
        },
        // 审批意见的宽度
        opinionLabelWidth() {
            let width = 110;
            if (!this.formOptData || this.formOptData.length <= 0) {
                return '110px'
            }
            let widthArr = this.formOptData.map(item => {
                return item.labelWidth;
            })
            // 取最大值
            width = Math.max(...widthArr);
            return `${width}px`;
        },
    },
    async created() {
        await this.initData()
    },
    methods: {
        async initData() {
            let id = this.OAQueryData.id
            let dataTitle = this.OAQueryData.dataTitle
            this.$eventDispatch('setGlobalPageType', 'detail')
            // 获取表单详情数据
            await this.getFormInfo(id, dataTitle);
            this.getLeaveType();
            // this.compareTime();
            this.getSubExcuseList()
            let specials = this.specialHandling.map(item => {
                return item.schoolId;
            });
            if(specials.includes(this.schoolId)) {
                await this.getCheckTeacherIsMaster();  // 判断是否是班主任
                this.getIsShowNeedApply();  // 判断是否显示 【入校提交申请】
            }
        },
        ...mapMutations(['setOAQueryData']),
        // 获取表单详情数据
        async getFormInfo(id, dataTitle) {
            let applyContent = {},
                reqId = '';

            const campusOA = new CampusOAModel();

            // schoolId
            const specialSchoolIds = this.specialHandling.map(item => {
                return item.schoolId;
            });
            const idx = specialSchoolIds.findIndex(item => {
                return item == this.schoolId
            });
            const content = idx >= 0 ? this.specialHandling[idx] : {};
            console.log(content,'content')

            await campusOA.getProcessApplyInfo({
                id: id,
                dataTitle: dataTitle
            }).then((res) => {
                if (res.data.code === '200') {
                    console.log(res.data,'res/data')
                    reqId = res.data.data.schoolProcessApplyInfo.fromInfo
                    this.schoolProcessInfo = res.data.data.schoolProcessApplyInfo
                    applyContent = res.data.data.schoolProcessApplyInfo.applyContent;
                    this.applyContent = applyContent
                    this.formInfo = res.data.data;
                } else if (res.data && res.data.msg) {
                    console.log(res.data.msg)
                }
            })
            await campusOA.getContentDetails({id: reqId}).then((res) => {
                if (res.data.code == '200') {
                    console.log(res.data,'getContentDetails----')
                    this.formInfo.schoolProcessApplyInfo.fromInfo = {
                        list: []
                    }
                    this.formInfo.schoolProcessApplyInfo.fromInfo.list = res.data.data;
                    this.formOptData = res.data.data.content.list;
                    let kitsLeaveAbsenceComps = [] // 请假/调休套件
                    let specialKitsLeaveAbsenceComps = [] // 特殊请假
                    let kitsDayStudentComps = [] // 走读申请请假
                    let addFormComps = [] // 新添加请假状态
                    let addRemarkFormComps = [] // 家长给学生提交销假的备注
                    let kitsShiftChangeComps = [] // 换班套件

                    this.formOptData.forEach((item) => {
                        console.log(item.compType,applyContent,'applyContent')
                        if (['applicant', 'applicantStu', 'approvalFormSelect', 'pickDropPersons', 'departureTime', 'returnTime', 'entryExitSelect'].includes(item.compType)) {
                            item.id = item.key
                        }
                        if (['applicant', 'applicantStu', 'personnelInput'].includes(item.compType)) {
                            item.value = applyContent[item.id] && applyContent[item.id].displayValue && applyContent[item.id].displayValue.length > 0 ? applyContent[item.id].displayValue.map(e => e.name).join("，") : ''
                        } else if (['address'].includes(item.compType)) {
                            item.value = applyContent[item.id] && applyContent[item.id].value ? applyContent[item.id].value.map(e => e.areaName).join(" / ") : ''
                        } else if (['approvalFormSelect', 'multipleSelect'].includes(item.compType)) {
                            item.value = applyContent[item.id] && applyContent[item.id].displayValue && applyContent[item.id].displayValue.length > 0 ? applyContent[item.id].displayValue.map(e => e.label).join("，") : ''
                        } else if (['upload', 'attachmentUpload'].includes(item.compType)) {
                            item.value = applyContent[item.id] ? applyContent[item.id].displayValue : []
                        } else if (['pickDropPersons', 'radio'].includes(item.compType)) {
                            item.value = applyContent[item.id] && applyContent[item.id].displayValue ? applyContent[item.id].displayValue.label : ''
                        } else if (item.compType === 'descriptionText') {
                            item.label = ''
                        } else if (['kitsLeaveAbsence'].includes(item.compType)) {
                            this.isAskForLeaveForm = true;
                            let leaveUnit = applyContent?.leaveType?.displayValue.leaveUnit;
                            const [startTime, endTime] =  this.determiningBrowserType(applyContent)
                            let format = leaveUnit == 2 ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd';
                            let leaveStartTimeFormat = format
                            let leaveEndTimeFormat = format
                            if (leaveUnit == 3) { // 按半天
                                leaveStartTimeFormat += applyContent?.leaveStartTime?.noonType == '2' ? " 下午" : " 上午"
                                leaveEndTimeFormat += applyContent?.leaveEndTime?.noonType == '2' ? " 下午" : " 上午"
                            }
                            let statusObj = {
                                '1':'待出校',
                                '2':'待入校',
                                '3':'已结束',
                                '4':'已销假'
                            }
                            let addFormCompsValue = ['1','2','3','4'].includes(this.schoolProcessInfo.excuseState) ? statusObj[this.schoolProcessInfo.excuseState] : '-';
                            if(['1','2'].includes(this.schoolProcessInfo.excuseTempState)) {//临时状态
                                statusObj = {
                                    '1':'撤销中',
                                    '2':'修改中'
                                }
                                addFormCompsValue = ['1','2'].includes(this.schoolProcessInfo.excuseTempState) ? statusObj[this.schoolProcessInfo.excuseTempState] : '-';
                            }
                            addFormComps = [
                                {
                                    label: "假条状态",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'customTipText',
                                    color: '#000',
                                    fontSize: 14,
                                    value: addFormCompsValue,
                                    marginLeft: '0px'
                                },
                            ]
                            addRemarkFormComps = [
                                {
                                    label: "备注",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'customTipText',
                                    color: '#000',
                                    fontSize: 14,
                                    value: this.schoolProcessInfo.manualCancelLeaveRemark != ''?this.schoolProcessInfo.manualCancelLeaveRemark:null,
                                    marginLeft: '0px'
                                },
                            ]
                            kitsLeaveAbsenceComps = [
                                {
                                    label: '请假类型',
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'leaveType',
                                    value: applyContent?.leaveType?.displayValue?.label ?? '',
                                    leaveUnit: applyContent?.leaveType?.displayValue?.leaveUnit ?? ''
                                },
                                {
                                    label: idx >= 0 ? content.rules.value1.labelName : "开始时间",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'leaveStartTime',
                                    format: leaveStartTimeFormat,
                                    value: startTime,
                                    noonType: applyContent?.leaveStartTime?.noonType ?? '',
                                    show: idx >= 0 ? content.rules.value1.show : true
                                },
                                {
                                    label: idx >= 0 ? content.rules.value2.labelName : "结束时间",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'leaveEndTime',
                                    format: leaveEndTimeFormat,
                                    value: endTime,
                                    noonType: applyContent?.leaveEndTime?.noonType ?? '',
                                    show: idx >= 0 ? content.rules.value2.show : true
                                },
                                {
                                    label: "时长",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'leaveDuration',
                                    value: applyContent?.leaveDuration?.value ?? '',
                                    unit: applyContent?.leaveType?.displayValue?.leaveUnit == '2' ? '小时' : '天'
                                },
                                {
                                    label: "请假事由",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'multiLineText',
                                    value: applyContent?.reasonLeave?.value ?? ''
                                },
                                {
                                    label: "实际开始时间",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'customTipText',
                                    tipDescription: this.schoolProcessInfo.departureType=='1'?'提前出校':null,
                                    color: '#000',
                                    tipcolor: '#4FBC0D',
                                    background: '#EDF8E6',
                                    fontSize: 14,
                                    value: (this.schoolProcessInfo.manualCancelLeaveRemark && this.schoolProcessInfo.manualCancelLeaveRemark != '') ? this.schoolProcessInfo.manualCancelLeaveStartTime?.slice(0,16) || '-' : this.schoolProcessInfo.actualDepartureTime?.slice(0,16) || '-',
                                    key:"sjcxsj",
                                    marginLeft: '0px'
                                },
                                {
                                    label: "实际结束时间",
                                    labelWidth: 104,
                                    span: 24,
                                    compType: 'customTipText',
                                    tipDescription: this.schoolProcessInfo.backType=='1'?'逾期返校':null,
                                    color: '#000',
                                    tipcolor: '#F05B5B',
                                    background: '#FDEEEE',
                                    fontSize: 14,
                                    value:  (this.schoolProcessInfo.manualCancelLeaveRemark && this.schoolProcessInfo.manualCancelLeaveRemark != '') ? this.schoolProcessInfo.manualCancelLeaveEndTime?.slice(0,16) || '-' : this.schoolProcessInfo.actualBackTime?.slice(0,16) || '-',
                                    key:'sjrxsj',
                                    marginLeft: '0px'
                                }
                            ];
                        } else if (['kitsSpecialLeave'].includes(item.compType)) {
                            let leaveUnit = applyContent?.leaveType?.displayValue.leaveUnit;
                            let format = leaveUnit === 1 ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH:mm'
                            specialKitsLeaveAbsenceComps = [
                                {
                                    label: '请假类型',
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'leaveType',
                                    value: applyContent?.leaveType?.displayValue?.label ?? ''
                                },
                                {
                                    label: "",
                                    labelWidth: 0,
                                    span: 24,
                                    compType: 'kitsSpecialLeave',
                                    value: applyContent?.specialLeave
                                },
                                {
                                    label: "请假总时长",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'leaveDuration',
                                    value: applyContent?.specialLeaveTotalDuration?.value ?? '',
                                    unit: applyContent?.leaveType?.displayValue?.leaveUnit == '1' ? '天' : '小时'
                                },
                                {
                                    label: "请假事由",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'multiLineText',
                                    value: applyContent?.reasonLeave?.value ?? ''
                                }
                            ];
                        } else if (item.compType === 'kitsShiftChange') { // 换班套件
                            kitsShiftChangeComps = [
                                {
                                    key: 'shiftChangeDate',
                                    label: "换班日期",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'shiftChangeDate',
                                    format: 'yyyy-MM-dd',
                                    value: applyContent?.shiftChangeDate?.value
                                },
                                {
                                    key: 'shiftWorker',
                                    label: "换班人",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'shiftWorker',
                                    value: applyContent.shiftWorker && applyContent.shiftWorker.displayValue && applyContent.shiftWorker.displayValue.length > 0 ? applyContent.shiftWorker.displayValue.map(e => e.name).join("，") : ''
                                },
                                {
                                    key: 'shiftChangeReason',
                                    label: "换班原因",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'multiLineText',
                                    value: applyContent?.shiftChangeReason?.value ?? ''
                                },
                                {
                                    key: 'dormInfo',
                                    label: "宿舍信息",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'dormInfo',
                                    value: applyContent?.dormInfo?.value ?? ''
                                }
                            ]
                        } else if (item.compType === 'kitsDayStudent') { // 走读申请套件
                            console.log(applyContent,'-----applyContent-----')
                            kitsDayStudentComps = [
                                {
                                    key: 'applicant',
                                    label: "申请人",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'applicant',
                                    value:applyContent.applicant && applyContent.applicant.displayValue && applyContent.applicant.displayValue.length > 0 ? applyContent.applicant.displayValue.map(e => e.name).join("，") : ''
                                },
                                {
                                    key:'studentType',
                                    label: '学生类型',
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'studentType',
                                    value:applyContent?.studentType?.value.toString()? this.getStudentType(applyContent.studentType.value) :''
                                    // value:applyContent?this.getStudentType(applyContent) :''
                                },
                                {
                                    key: 'dayStudentStartDate',
                                    label: "开始日期",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'dayStudentStartDate',
                                    format: 'yyyy-MM-dd',
                                    value: applyContent?.dayStudentStartDate?.value
                                },
                                {
                                    key: 'dayStudentEndDate',
                                    label: "结束日期",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'dayStudentEndDate',
                                    format: 'yyyy-MM-dd',
                                    value: applyContent?.dayStudentEndDate?.value
                                },
                                {
                                    key: 'dayStudentReason',
                                    label: "申请原因",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'multiLineText',
                                    value: applyContent?.dayStudentReason?.value ?? ''
                                },
                                {
                                    key: 'descriptionText',
                                    label: "",
                                    labelWidth: 90,
                                    // span: 24,
                                    compType: 'descriptionText',
                                    // value: applyContent?.descriptionText?.value ?? '',
                                },

                                {
                                    key: 'attachmentUpload',
                                    label: "上传附件",
                                    labelWidth: 90,
                                    span: 24,
                                    compType: 'attachmentUpload',
                                    value: applyContent.attachmentUpload ? applyContent.attachmentUpload.displayValue : []
                                },
                            ]
                        } else if (item.compType === 'dateTime') {
                            if (item.status === 'datetime') { // 年月日 时分秒
                                item['format'] = 'yyyy-MM-dd HH:mm:ss'
                            } else if (item.status === 'daterange') { // 年月日
                                item['format'] = 'yyyy-MM-dd'
                            }
                            item.value = applyContent[item.id] ? applyContent[item.id].value : ''
                        } else {
                            item.value = applyContent[item.id] ? applyContent[item.id].value : ''
                        }
                    })
                    // 请假/调休套件
                    let kitsLeaveAbsenceIndex = this.formOptData.findIndex(e => e.compType === "kitsLeaveAbsence")
                    if (kitsLeaveAbsenceIndex > -1) {
                        this.formOptData.forEach(item => {
                            item.labelWidth = 104;
                            item.compType == "applicantStu" && (item.longLabel = true)
                        })
                        this.isShowExcuseStatus = true
                        this.formOptData.splice(kitsLeaveAbsenceIndex, 1, ...kitsLeaveAbsenceComps)
                        this.formOptData.unshift(...addFormComps)  // 新添加请假状态
                        console.log('this.schoolProcessInfo.manualCancelLeaveRemark',this.schoolProcessInfo.manualCancelLeaveRemark);
                        if (this.schoolProcessInfo.manualCancelLeaveRemark && this.schoolProcessInfo.manualCancelLeaveRemark != '') {
                            // 如果是销假审批中/审批通过，增加备注行
                            this.formOptData.push(...addRemarkFormComps)  // 家长给学生销假的备注
                        }
                    }
                    // 特殊请假
                    let specialLeaveAbsenceIndex = this.formOptData.findIndex(e => e.compType === "kitsSpecialLeave")
                    if (specialLeaveAbsenceIndex > -1) {
                        this.isShowExcuseStatus = true
                        this.formOptData.splice(specialLeaveAbsenceIndex, 1, ...specialKitsLeaveAbsenceComps)
                    }
                    // 换班套件
                    let kitsShiftChangeIndex = this.formOptData.findIndex(e => e.compType === "kitsShiftChange")
                    if (kitsShiftChangeIndex > -1) {
                        this.formOptData.splice(kitsShiftChangeIndex, 1, ...kitsShiftChangeComps)
                    }
                    // 走读申请套件
                    let kitsDayStudentIndex = this.formOptData.findIndex(e => e.compType === "kitsDayStudent")
                    if (kitsDayStudentIndex > -1) {
                        let dayStudentComment = this.formOptData[kitsDayStudentIndex].dayStudentComment
                        let fontSize =  this.formOptData[kitsDayStudentIndex].fontSize
                        let fontColor = this.formOptData[kitsDayStudentIndex].fontColor
                        this.formOptData.splice(kitsDayStudentIndex, 1, ...kitsDayStudentComps)
                        this.formOptData = this.formOptData.map((item)=>{
                            if(item.compType == "descriptionText"){
                                item.value = dayStudentComment
                                item.color = fontColor
                                item.fontSize = fontSize
                            }
                            return item
                        })
                    }
                } else if (res.data && res.data.msg) {
                    console.log(res.data.msg)
                }
            })
        },
        /**
         * @Description: 获取请假类型
         * @DoWhat: 根据当前这个表单详情的请假类型是否有余额，判断是不是要获取余额接口
         * @UseScenarios: 
         * @Attention: 
         * @Author: 闫乔
         * @Date: 2024-05-20 14:40:39
         */        
        async getLeaveType() {
            return new Promise((resolve) => {
                const vacationManagementModel = new CoworkingCampusOAVacationManagementModel();
                // type: 1是员工2是学生
                const typeMap = {
                    "teacher": 1,
                    "student": 2
                };
                let type = 1;
                // applicant
                if (this.applyContent.applicant && this.applyContent.applicant.value && this.applyContent.applicant.value.length > 0) {
                    // 仅申请人 = 1个人的时候，允许使用设置了余额的假期
                    if (this.applyContent.applicant.value.length === 1) {

                        type = typeMap[this.applyContent.applicant.value[0].type]
                    } else {   // 多个申请人的时候，不允许使用设置了余额的假期（在请假类型里面不展示设置了余额的假期）
                        const student = this.applyContent.applicant.value.find(i => i.type === "student")
                        const teacher = this.applyContent.applicant.value.find(i => i.type === "teacher")
                        if (student && teacher) {

                            resolve([])
                            return
                        } else {

                            type = typeMap[this.applyContent.applicant.value[0].type]
                        }
                    }
                } else if (Object.prototype.hasOwnProperty.call(this.applyContent, 'applicant')){
                    let val = this.applyContent.applicant['value'] || [];
                    const student = val.find(i => i.type === "student")
                    const teacher = val.find(i => i.type === "teacher")

                    if (val.length > 0) {
                        if (student && teacher) {
                            resolve([])
                            return
                        } else {
                            type = typeMap[val[0].type];
                        }
                    } else {
                        type = 1
                    }
                }

                // 判断是不是衡中或者衡实，如果是衡中或者衡实就传值
                //
                let specials = this.specialHandling.map(item => {
                    return item.schoolId;
                });
                let formName = specials.includes(this.schoolId) ? this.formTitle : '';

                vacationManagementModel.getUndeletedList({
                    formName: formName,
                    schoolId: this.schoolId,
                    deleteStatus: "0",
                    type: type,
                    status: "1",//开启状态 1开启；0关闭
                }).then((res) => {
                    // 允许员工申请调休及所有假期的请假单
                    if (type == 1) {
                        // res.data.data = res.data.data.filter(e => e.vacationName.indexOf("调休") === -1)
                    } else {
                        res.data.data = res.data.data.filter(e => e.vacationName.indexOf("调休") === -1)
                    }
                    
                    // 有多个申请人不允许选择按半天的假期
                    if (this.applyContent.applicant && this.applyContent.applicant.value && this.applyContent.applicant.value.length > 1) {
                        res.data.data = res.data.data.filter(e => e.leaveUnit != 3)
                    }
                    // 有多个申请人不允许使用设置了余额的假期
                    if (this.applyContent.applicant && this.applyContent.applicant.value && this.applyContent.applicant.value.length > 1) {
                        res.data.data = res.data.data.filter(e => e.balanceSwitch != 1)
                    }
                    // 没有选择申请人 并且 当前登录人是系统管理员,过滤掉设置了余额的假期
                    const adminTeacherId = this.applyContent.applicant && this.applyContent.applicant.value && this.applyContent.applicant.value.length > 0
                        ? this.applyContent.applicant.value[0].id
                        : this.$store.state.teacherId;
                    console.log('adminTeacherId',adminTeacherId);
                    if (!adminTeacherId) {
                        res.data.data = res.data.data.filter(e => e.balanceSwitch != 1)
                    }
                    
                    console.log('请假类型数据获取审批时的',res.data.data);
                    // 隐藏请假结束时间的学校过滤按半天的假期
                    if (specials.includes(this.schoolId)) {
                        res.data.data = res.data.data.filter(e => e.leaveUnit != 3)
                    }
                    this.useVacationList = res.data.data;
                    console.log('useVacationList所有的假期类型',this.useVacationList);
                    
                    resolve(res.data.data.map((i) => ({
                        value: i.id,
                        label: i.vacationName,
                        leaveUnit: i.leaveUnit,
                    })))
                });
            })
        },
        //获取学生类型
        getStudentType(e){
            console.log('执行了吗')
            let index = this.studentTypeList.findIndex((item) => {
                return item.value == e ;
            });
            if(index <0){
                return ''
            }else{
                return this.studentTypeList[index].label
            }

        },
        /**
         * @Description: 判断浏览器类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-05-12 10:28:41
         * @param {*} name
         */
        determiningBrowserType (applyContent) {
            let explorer = window.navigator.userAgent,
                startTime = applyContent?.leaveStartTime?.value ?? '',
                endTime = applyContent?.leaveEndTime?.value ?? '';

            if(explorer.indexOf("Safari") >= 0){
                startTime = startTime.split('').map((item) => {
                    return item.replace('-', '/')
                }).join('');
                endTime =  endTime.split('').map((item) => {
                    return item.replace('-', '/')
                }).join('');
            }

            return [startTime, endTime]
        },
        // 请假时长明细弹窗打开
        handleLeaveDurationDetailsShow() {
            console.log("[handleLeaveDurationDetailsShow]this.formOptData:", this.formOptData, "this.formInfo:", this.formInfo, "this.applyContent:", this.applyContent)
            let startTimeStr = this.applyContent.leaveStartTime.value.split(" ")[0]
            let startType = this.applyContent.leaveStartTime.noonType
            let endTimeStr = this.applyContent.leaveEndTime.value.split(" ")[0]
            let endType = this.applyContent.leaveEndTime.noonType

            // let startTime = new Date(startTimeStr).getTime()
            // let endTime = new Date(endTimeStr).getTime()
            // if (startTime > endTime || (startTime === endTime && startType === "2" && endType === "1")) {
            //     this.$toast("请假开始时间应小于请假结束时间")
            //     return
            // }

            let applyTeacherId = this.formInfo.applyTeacherId
            if (this.applyContent.applicant && this.applyContent.applicant.value && this.applyContent.applicant.value.length > 0) {
                applyTeacherId = this.applyContent.applicant.value[0].id
            }
            this.leaveDurationDetailsDialogObj.data = {
                applyTeacherId,
                startTimeStr,
                startType,
                endTimeStr,
                endType
            }
            this.leaveDurationDetailsDialogObj.dialogVisible = true;
        },
        // 请假时长明细弹窗关闭
        handleLeaveDurationDetailsClose() {
            this.leaveDurationDetailsDialogObj.dialogVisible = false;
        },
        //校验假条状态
        checkLeaveStatus(key) {
            let id = this.OAQueryData.id
            let dataTitle = this.OAQueryData.dataTitle
            return new Promise(resolve => {
                const campusOA = new CampusOAModel();
                campusOA.getProcessApplyInfo({
                    id: id,
                    dataTitle: dataTitle
                }).then((res) => {
                    this.handleRes(res, () => {
                        if(res.data.data[key] == true){
                            resolve(true)
                        }else{
                            this.$message.error('假条已结束，不可被撤销或修改。');
                            setTimeout(() => {
                                this.initData();
                            }, 1500)
                            resolve(false)
                        }
                    })
                }).catch(err => {
                    resolve(false)
                })
            })
        },
        leftTabClick(name) {
            this.leftActiveName = name;
        },
        // 撤销
        async handleRevoke() {
            let leaveStatus = await this.checkLeaveStatus('isRevoke');
            if (!leaveStatus) {
                return
            }
            const campusOA = new CampusOAModel()
            this.$prompt('请输入撤回理由', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^.+$/,
                inputErrorMessage: '撤回理由不能为空'
            }).then(({value}) => {
                campusOA.schoolProcessRevoke({
                    id: this.formInfo.schoolProcessApplyInfo.id,
                    dataTitle: this.formInfo.schoolProcessApplyInfo.dataTitle,
                    revokeReason: value,
                }).then((res) => {
                    this.handleRes(res, () => {
                        this.$message.success('撤销成功')
                        this.handleBack()
                    })
                })
            }).catch(() => {
            });
        },
        /**
         * @Description: 选择 入校需要提交入校申请
         * @DoWhat: 1 是 2 否
         * @UseScenarios: 
         * @Attention: 
         * @Author: 闫乔
         * @Date: 2024-05-31 15:47:04
         */        
         handleChangeNeedApply(data) {
            console.log('选择了',data);
         },
        // 同意 拒绝
        async handleClick(data) {
            // 先把是否超出余额设置为false
            this.isOverLeaveUnit = false;
            // 点击【同意】，做出判断， 并且不是撤销的假条
            if (data == 2 && this.formInfo.schoolProcessApplyInfo.isRevoke != 1) {
                console.log('不是撤销');
                let nowLeaveTypeInfo = this.useVacationList.find(e => e.id == this.applyContent.leaveType?.value);
                // 先判断请假类型是否设置了余额
                if (nowLeaveTypeInfo && nowLeaveTypeInfo.balanceSwitch && nowLeaveTypeInfo.balanceSwitch == 1) {
                    // 表单状态 = 除去撤销审批中，其他审批中的状态，点击同意按钮
                    if (this.schoolProcessInfo.isRevoke != '1') {
                        // 获取余额，判断余额与时长的关系
                        await this.changeVacationLeaveType();
                    }
                } else {
                    this.isOverLeaveUnit = false;
                }
            } else {
                console.log('是撤销');
                // 点击【拒绝】，不做判断,是否超出余额设置为false
                this.isOverLeaveUnit = false;
            }
            console.log('this.isOverLeaveUnit是否超过',this.isOverLeaveUnit);
            if (!this.isOverLeaveUnit) {
                
                let admissionNeedApplyData = '';
                // 如果不是撤销审批中
                if (this.formInfo.schoolProcessApplyInfo.isRevoke != 1) {
                    admissionNeedApplyData = this.applyContent.admissionNeedApply?.value == '1' ? '1' : this.admissionNeedApply
                } else {
                    admissionNeedApplyData = this.admissionNeedApply
                }
                if (data == 2 && this.formInfo.isOpenSignature == 1) {
                    // 同意并且表单开启了签名开关，则显示审批同意弹窗
                    this.approvalDialogObj.dialogVisible = true;
                    this.approvalDialogObj.key++;
                    this.approvalDialogObj.approveOpinion = this.approveOpinion;
                    this.approvalDialogObj.data = data;
                    this.approvalDialogObj.admissionNeedApplyData = admissionNeedApplyData;
                    console.log(this.approvalDialogObj)
                } else {
                    // 直接调用接口
                    this.handleApprovalConfirm(data, admissionNeedApplyData, this.approveOpinion)
                } 
            }
        },
        // 审批同意-取消
        handleApprovalCancel() {
            this.approvalDialogObj.dialogVisible = false
        },
        // 审批同意-确定
        async handleApprovalConfirm(data, admissionNeedApplyData, approveOpinion) {
            console.log('admissionNeedApplyData的值',admissionNeedApplyData);
            const campusOA = new CampusOAModel()
            campusOA.schoolProcessApproval({
                id: this.formInfo.schoolProcessApplyInfo.id,
                dataTitle: this.formInfo.schoolProcessApplyInfo.dataTitle,
                approveStats: data,
                approveOpinion: approveOpinion,
                admissionNeedApply: admissionNeedApplyData
            }).then((res) => {
                this.handleRes(res, () => {
                    this.$message.success('审批成功')
                    this.approvalDialogObj.dialogVisible = false;
                    this.handleBack()
                })
            })
        },
        // 修改
        async handleEdit() {
            let leaveStatus = await this.checkLeaveStatus('isEdit');
            if (!leaveStatus) {
                return
            }
            this.$router.push({
                name: 'CoworkingCampusOAOAApprovalFormDetail',
                query: {
                    activeName: '1',
                    formName: this.OAQueryData.dataTitle
                }
            })
            this.setOAQueryData({
                formId: this.formInfo.schoolProcessApplyInfo.formId,
                id: this.OAQueryData.id,
                dataTitle: this.OAQueryData.dataTitle,
                backData: this.OAQueryData.backParentData || this.OAQueryData.backData
            })
        },
        //销假
        async handleCancelLeave() {
            let leaveStatus = await this.checkLeaveStatus('isManualCancelLeave');
            if (!leaveStatus) {
                return
            }
            let data = this.formOptData;
            let applyContent = this.applyContent;
            let obj = {};
            obj.id = this.OAQueryData.id
            obj.leaveType = applyContent.leaveType.displayValue.leaveUnit;
            obj.leaveTypeId = applyContent.leaveType.displayValue.value;
            console.log("[handleCancelLeave]applyContent.applicant:", this._.cloneDeep(applyContent.applicant))
            if (applyContent.applicant && applyContent.applicant.displayValue && applyContent.applicant.displayValue[0]) {
                obj.applicant = applyContent.applicant.displayValue[0].name;
                if (applyContent.applicant.displayValue.length > 1) {
                    obj.applicant += "等"
                }
            } else { // 没有申请人从流程数据中取提交人
                console.log("[handleCancelLeave]this.formInfo.processList:", this._.cloneDeep(this.formInfo.processList))
                let applicantProcessItem = this.formInfo.processList.find(e => e.designName === "发起申请")
                if (applicantProcessItem && applicantProcessItem.person && applicantProcessItem.person.length > 0) {
                    obj.applicant = applicantProcessItem.person[0].name
                }
            }
            obj.leaveStartTime = data.find(item => item.compType == "leaveStartTime").value.replaceAll('/','-');
            obj.leaveEndTime = data.find(item => item.compType == "leaveEndTime").value.replaceAll('/','-');
            obj.leaveSchoolTime = data.find(item => item.key && item.key=='sjcxsj').value;
            obj.enterSchoolTime = data.find(item => item.key && item.key=='sjrxsj').value;
            if(obj.leaveSchoolTime == '-'){
                obj.leaveSchoolTime = '';
            }
            if(obj.enterSchoolTime == '-'){
                obj.enterSchoolTime = '';
            }
            if(obj.leaveSchoolTime) {
                obj.leaveSchoolTime = this.$moment(new Date(obj.leaveSchoolTime)).format("YYYY-MM-DD HH:mm")
            }
            if(obj.enterSchoolTime) {
                obj.enterSchoolTime = this.$moment(new Date(obj.enterSchoolTime)).format("YYYY-MM-DD HH:mm")
            }
            this.formData = obj;
            this.showCancelLeave = true;
        },
        //销假弹框关闭
        handleCancelLeaveClose() {
            this.handleCancel();
            setTimeout(() => {
                this.initData();
            },500)
        },
        handleCancel(){
            this.showCancelLeave = false;
        },
        async handleBack() {
            console.log("[handleBack]this.OAQueryData.backData", this._.cloneDeep(this.OAQueryData.backData))
            if(this.OAQueryData.backData) {
                if (this.OAQueryData.backData.name === "CoworkingCampusOAOAApprovalFormDetail") {
                    this.setOAQueryData({
                        formId: this.formInfo.schoolProcessApplyInfo.formId,
                        backData: this.OAQueryData.backParentData
                    })
                } else if (this.OAQueryData.backData.name === "CoworkingCampusOAOAApprovalApplyDetail"){
                    this.setOAQueryData({
                        id:this.OAQueryData.parentId,
                        dataTitle:this.OAQueryData.dataTitle,
                        backData: this.OAQueryData.backParentData,
                        backParentData: this.OAQueryData.backOldParentData,
                        formId: this.OAQueryData.formId,
                    })
                    await this.initData()
                    this.leftActiveName = 3
                }
            } else {
                this.setOAQueryData({})
            }
            let activeItem = this.$route.query.activeItem ? this.$route.query.activeItem : null;
            if (activeItem) {
                this.$router.push({
                    name: "SummaryStudentInfo",
                    query: {
                        ...JSON.parse(activeItem)
                    }
                });
            } else {
                this.$router.go(-1)
            }
        },
        // 接口请求结果处理
        handleRes(res, fn) {
            if (res.data.code === '200') {
                fn()
            } else if (res.data && res.data.msg) {
                this.$message.error(res.data.msg)
                this.initData()
            }
        },
        //计算当前时间是否早于请假开始时间
        compareTime() {
            let seconds = 10;
            let timer = null;
            let leaveStartTime = this.formOptData.find(item => item.compType == "leaveStartTime").value.replaceAll('/','-');
            let now = this.$moment().format("YYYY-MM-DD HH:mm:ss");
            let minutes = this.$moment(leaveStartTime).diff(this.$moment(), "minutes");
            console.log('minutes=====', minutes);
            if(this.formInfo.isManualCancelLeave || minutes > 10 || minutes < 0 || String(minutes) == 'NaN'){//可以销假  不再计算 请假开始时间比当前时间晚10分钟以上就不计算了
                return
            }
            if(minutes > 1){
                seconds = seconds * 6;
                console.log('seconds=====', seconds);
            }
            timer = setInterval(() => {
                console.log('计算一次======', now);
                now = this.$moment().format("YYYY-MM-DD HH:mm:ss");
                if(now >= leaveStartTime) {
                    this.formInfo.isManualCancelLeave = true;
                    clearInterval(timer);
                    timer = null;
                }
            },seconds * 1000)
        },
        // 进入子假条详情
        async handleGoSubExcuseDetail(item) {
            if(this.subJumpToDetailLoading){
                return
            }
            this.subJumpToDetailLoading = true;
            const _this = this;
            let { id } = item;
            this.setOAQueryData({
                id: id,
                parentId:_this.OAQueryData.id,
                dataTitle:_this.OAQueryData.dataTitle,
                backOldParentData: _this.OAQueryData.backParentData,
                backParentData: _this.OAQueryData.backData,
                formId: this.formInfo.schoolProcessApplyInfo.formId,
                
                backData: {
                    name: 'CoworkingCampusOAOAApprovalApplyDetail',
                },
            })
            await this.initData()
            this.leftActiveName = 1
            this.subJumpToDetailLoading = false;
        },
        // 获取子假条列表
        async getSubExcuseList() {
            // getSpecialChildApplyInfo
            if(this.formInfo.schoolProcessApplyInfo && this.formInfo.schoolProcessApplyInfo.formType == '3'){
                const campusOA = new CampusOAModel()
                await campusOA.getSpecialChildApplyInfo({
                    id:this.formInfo.schoolProcessApplyInfo.id
                }).then((res) => {
                    this.handleRes(res, () => {
                        this.subExcuseData = res.data.data;
                    })
                }).catch(() => {})
            }
        },
        // 修改子假条为全部撤销
        handleAfterRevokeAll() {
            if(!this.subExcuseData.list){
                return
            }
            // userType,1是管理员或者班主任,2是普通用户或家长
            let userType = this.subExcuseData.userType;
            if(userType == "1") {
                // 手动隐藏全部撤销按钮
                this.subExcuseData.isShow = false;
                // 可撤销状态 excuseState 字段与 approveStats 字段集合
                const excuseStateArr = ["1"];
                const approveStatsArr = ["2","5"]

                //修改列表内每项的状态
                this.subExcuseData.list.forEach(item => {
                    // 同时满足才可进行修改
                    if(excuseStateArr.includes(item.excuseState) && approveStatsArr.includes(item.approveStats)){
                        item.approveStats = "4";
                        item.excuseState = null;
                        item.excuseTempState = null; 
                    }
                    item.showRevoke = false;
                    // item.excuseTempState
                })

            } else if (userType == "2") {
                // 获取子假条列表
                this.getSubExcuseList()
            }

            setTimeout(() => {
                this.initData();
            },1500)
            
        },
        /**
         * @Description: 选择了请假类型，获取余额详情
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 闫乔
         * @Date: 2024-05-11 16:49:31
         */          
         async changeVacationLeaveType(){
            this.isOverLeaveUnit = false;
            // 初始化提示信息和调休数据
            this.overLeaveTime = this.$options.data.call(this).overLeaveTime;
            const commonOAModel = new CampusOAModel();
            const leaveTeacherId = this.applyContent.applicant.value && this.applyContent.applicant.value.length > 0
                    ? this.applyContent.applicant.value[0].id
                    : this.$store.state.teacherId;
            if (!leaveTeacherId) {
                this.isOverLeaveUnit = false;
                this.overLeaveTime = 0;
                return
            }
            let balanceForm = {}
            let editFormData = {
                schoolId: this.schoolId,
                vacationId: this.applyContent.leaveType.value,
                teacherId: leaveTeacherId,
                applyId: this.OAQueryData.id,
                isEdit: '1'
            }
            let oriFormData = {
                schoolId: this.schoolId,
                vacationId: this.applyContent.leaveType.value,
                teacherId: leaveTeacherId
            }
            balanceForm = oriFormData;
            // 如果是修改中的单子，审批
            if (this.schoolProcessInfo.excuseTempState == '2') {
                balanceForm = editFormData
            }
            console.log('balanceForm',balanceForm);
            await commonOAModel.getVacationLeaveBalance(balanceForm).then((res) => {
                if (res.data.code == "200") {
                    console.log('获取员工的假期余额',res.data.data.leaveBalance);
                    // 设置剩余调休时长和请假单位
                    const { leaveBalance,leaveUnit } = res.data.data;
                    if (typeof res.data.data.leaveBalance != "undefined") {
                        this.overLeaveTime = leaveBalance;
                        if ([1, 2, 3].includes(leaveUnit)) {
                            if (this.applyContent.leaveDuration.value > this.overLeaveTime) {
                                this.$message.warning('余额不够用');
                                this.isOverLeaveUnit = true;
                            } else {
                                this.isOverLeaveUnit = false;
                            }
                            console.log('this.overLeaveTime',this.overLeaveTime,'this.applyContent.leaveDuration.value',this.applyContent.leaveDuration.value);
                            
                        }
                    }
                } else if (res.data.code == "022501") {
                    console.log('未查到假期余额配置');
                } else if (res.data.code == "022502") {
                    this.isOverLeaveUnit = true;
                    this.$message.error(res.data.msg);
                } else if (res.data && res.data.msg) {
                    this.isOverLeaveUnit = true;
                    this.$message.error(res.data.msg);
                }
            })
        },
        /**
         * @Description: 判断是否展示 【入校提交申请】
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 闫乔
         * @Date: 2024-05-11 16:49:31
         */ 
        getIsShowNeedApply() {
            // 是否显示入校申请组件
            /**
             * 1.衡水中学 311 / 222
             * 2.账号 = 教师
             * 3.审批人 = 班主任
             * 4.申请人 = 学生
             *  5.请假类型 不等于【违纪】
             * */
            // 获取表单内容
            let specials = this.specialHandling.map(item => {
                return item.schoolId;
            });
            let isHasTeacherId = this.$store.state.teacherId;
            if(specials.includes(this.schoolId)) {  // 1. 如果是衡中/衡实  311 / 222
                if (isHasTeacherId) {  // 2. 如果当前登录账号是教师
                    if (this.isMasterTeacher) {  // 3. 如果审核人是班主任
                        if (this.applyContent.applicant.value[0].type == 'student') {   // 4. 如果申请人 是学生
                            let currentType = this.applyContent.leaveType?.displayValue?.label;
                            if (currentType && currentType.indexOf("违纪") === -1) {  // 5. 如果请假类型 不等于【违纪】
                                this.showNeedApply = true;
                            }
                        }
                    }
                }
            }
         },
        /**
        * @Description: 判断当前教师是否为班主任
        * @DoWhat: 判断显示  【入校提交申请】 组件
        * @UseScenarios: 
        * @Attention: 
        * @Author: 闫乔
        * @Date: 2024-06-03 11:07:30
        */        
        async getCheckTeacherIsMaster() {
            const campusOA = new CampusOAModel()
            await campusOA.getCheckIsMasterTeacher({
                id: this.$store.state.teacherId
            }).then((res) => {
                this.handleRes(res, () => {
                    let isMaster = res.data.data
                    this.isMasterTeacher = isMaster == 1 ? true : false;
                })
            }).catch(() => {})
        },
        /**
         * @Description: 导出pdf
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-11-04 09:43:57
         */         
        async exportPDF(){
            await this.getPdfTotal();
            this.dialogObj.dialogVisible = true;
        },   
        /**
         * @Description: 导出pdf--弹窗关闭
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-11-04 09:43:57
         */        
        handleDialogClose() {
            this.dialogObj.dialogVisible = false;
        },
        /**
         * @Description: 根据id获取PDF文件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-11-04 09:43:57
         */
        async getPdfTotal() {
            let id = this.formInfo.schoolProcessApplyInfo.id;
            let res =  await this._fet('/oa/schoolProcessApply/downApplyPdf', {
                id: id
            })
            if(res.data.code == "200"){
                console.log(res);
                this.pdfDownLoadUrl = res.data.data.urlPath;
                this.pdfFileName = res.data.data.fileName;

                this.pdfUrl = Pdf.createLoadingTask({
                    url: this.pdfDownLoadUrl,
                    CMapReaderFactory: CMapReaderFactory,
                    cMapPacked: true,
                })
                console.log('pdfUrl-------',this.pdfUrl);
                this.pdfUrl.promise.then(pdf => {
                    console.log(111,pdf);
                    this.pageTotal = pdf.numPages;
                })
                .catch((err) => {
                    console.error("pdf 加载失败", err);
                });
            }
        },
        /**
         * @Description: 导出pdf--下载
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-11-04 09:50:05
         */        
        download(){
            this.downloadPdf(this.pdfDownLoadUrl,this.pdfFileName)
            this.handleDialogClose();
        },
        /**
         * @Description: 下载文件
         * @DoWhat: 下载pdf
         * @UseScenarios:
         * @Attention:
         * @Author: 王妙田
         * @Date: 2024-11-04 09:50:05
         * @param { String } urls 下载地址
         * @param { String } fileName 文件名
         */
        downloadPdf(urls, fileName) {
            this.$message({
                type: 'success',
                message: '文件下载中，请稍后...',
                duration: 5000
            });
            //判断文件名称是否带有后缀，没有后缀添加一个.MP4的后缀（解决自动上传的视频无后缀下载无法播放问题）
            var newFileName = fileName.split(".");
            newFileName = newFileName[newFileName.length - 1];
            if (fileName === newFileName) {
                // fileName = fileName + '.mp4'
                fileName = fileName + urls.match(/.[^.]+$/)[0]
            }
            const x = new window.XMLHttpRequest();
            x.open('GET', urls, true);
            x.responseType = 'blob';
            x.onload = () => {
                const url = window.URL.createObjectURL(x.response);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank'
                a.download = fileName;
                a.style.display = 'none'
                document.body.append(a)
                a.click();
            };
            x.send();
        },
    }
}
</script>

<style scoped lang="scss">
.details-wrap {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 75px);

    ::v-deep .global-page-back {
        position: relative;
        z-index: 99;
        box-shadow: -2px 0px 8px 0px #E1E3E6;
        flex-shrink: 0;

        .back-wrapper {
            padding-right: 27px;
            border-right: 1px solid #D4D6D9;
        }

        .tab-list {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .global-page-right {
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translate(0, -50%);
        }
    }

    .tabs-list {
        display: flex;
        height: 48px;
        line-height: 48px;

        .tabs-item {
            width: 96px;
            position: relative;
            text-align: center;
            font-size: 15px;
            color: #606C80;
            cursor: pointer;
        }

        .tabs-item.current {
            color: #2B2E33;
            font-weight: bold;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                height: 3px;
                width: 30px;
                background: #3C7FFF;
                border-radius: 2px;
            }
        }
    }

    .apply-detail-wrap {
        height: 100%;
        border-radius: 4px 0 0 4px;
        padding: 10px 0;
        overflow: auto;
        background-color: #fff;

        .apply-detail-content {
            min-height: 100%;
            max-width: 960px;
            background-color: #fff;
            padding: 48px 5%;
            margin: 0 auto;
            box-sizing: border-box;
            box-shadow: -2px 0 8px 0 #E1E3E6;
            border-radius: 4px;
            overflow: hidden;
            position: relative;
            .awaySchoolStatus{
                position: absolute;
                top: 10px;
                right: 10px;

            }

            .split-line {
                width: 100%;
                height: 1px;
                background-color: #D4D6D9;
                margin: 20px 0;
            }

            .operate-radio {
                text-align: left;
                width: 95%;
                height: 36px;
                line-height: 36px;
            }
            .operate-btn {
                text-align: center;
                width: 95%;

                .advice-input {
                    margin-bottom: 20px;

                    /deep/ .el-textarea__inner {
                        font-family: Microsoft YaHei;
                    }
                }
            }
            .cancel-leave-btn{
                background-color: #B83CFF !important;
            }
        }
    }
}
.dis-radio-class {
    ::v-deep {
        .el-radio__input.is-disabled + span.el-radio__label{
            color: #2b2f33;
        }
    }
}

/deep/ .approval-dialog.el-dialog {
    .el-dialog__body {
        padding: 32px;
        margin: 0;
    }
}

</style>
